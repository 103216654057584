import './polyfills';

import * as Sentry from '@sentry/react';
import { initializeApiClient } from 'shared/api-client';
import { API } from 'shared/config';
import { initTokens } from 'shared/tokens';

import { clearStorage } from './lib/clearStorage';
import { logoutRedirect } from './services/new-utils';

import { handleLazyLoadingErrors } from '@/lib/handleLazyLoadingErrors';
import { APP_VERSION, COMMIT_ID } from '@/lib/version';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://2d9c93c600ab49289544bc6087f8e00a@o572739.ingest.sentry.io/5722440',
    release: `${import.meta.env.VITE_NAME}@${APP_VERSION}+${COMMIT_ID}`,
    environment: import.meta.env.VITE_STAGE,
    allowUrls: [/https?:\/\/.*scoopr.no/],
    ignoreErrors: [
      /**
       * React internal error thrown when something outside react modifies the DOM
       * This is usually because of a browser extension or Chrome's built-in translate
       */
      "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
      // Web vitals
      "TypeError: Cannot read properties of undefined (reading 'domInteractive')",
      "TypeError: undefined is not an object (evaluating 'this._perf.domInteractive')",
      // Tiktok analytics
      "Can't find variable: ttq",
      'ttq is not defined',
    ],
    beforeSend: (event, hint) => {
      const stack = hint.syntheticException?.stack;

      if (
        stack?.includes('chrome-extension://') ||
        stack?.includes('i18n/pixel/static/main') ||
        stack?.includes('gtag/js')
      ) {
        return null;
      }

      return event;
    },
  });
}

initializeApiClient({
  canObtainNewTokens: (requestUrl, requestBody) => {
    if (window.location.pathname.startsWith('/agent-portal')) {
      return false;
    }

    // Legion flow starting request
    if (
      requestUrl === `${API.url}/brain/api/legion/` &&
      ['init', 'goal'].includes(requestBody?.content_type)
    ) {
      return true;
    }

    // Winners page
    if (
      requestUrl === `${API.url}/events_management/events/march_2024_campaign/`
    ) {
      return true;
    }

    return false;
  },
  onLogout: () => {
    clearStorage();
    logoutRedirect();
  },
});

handleLazyLoadingErrors();
initTokens();

console.log(`Portal version: ${APP_VERSION} (${COMMIT_ID})`);
