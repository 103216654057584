export const mainRoutes = {
  HOME: '/',
  PRODUCTS_REFINANCING: '/refinansiering',
  MY_ECONOMY: '/din-okonomi',
  PRODUCTS_REFINANCING_CHAT: '/refinansiering/:flow/:step', // separate route to avoid conflict with PRODUCTS_FAQ_ARTICLE route
  LIVE_OFFERS: '/tilbud/:appId',
  PRODUCTS_FAQ_ARTICLE: '/refinansiering/:articleName',
  ABOUT: '/betaler-du-mer-enn-du-maa',
  CUSTOMER_SERVICE: '/kundeservice',
  TERMS_OF_USE: '/brukervilkar',
  TERMS_OF_USE_MIN_SCOOPR: '/brukervilkar-min-scoopr',
  JOBS: '/jobb',
  TERMS_OF_USE_REFINANCING: '/brukervilkar-scoopr-refinansiering',
  TERMS_OF_USE_MORTGAGE: '/brukervilkar-scoopr-boliglan',
  PRIVACY: '/personvernerkaering',
  PRIVACY_STATEMENT: '/personvernerklaering',
  COOKIES: '/cookies',
  ERROR_PAGE: '/oops',
  ABANDONED_SESSIONS_CHAT: '/soknad/:flow/:step',
  VERIFIED_REFINANCING: '/debt-check/:flow/:step',
  COMPETITION_WINNERS: '/tidligere-trekninger',
  LOGGED_OUT: '/logget-ut',

  DECEMBER_2023_COMPETITION: '/julekalender-2023',
  DECEMBER_2023_COMPETITION_TERMS: '/julekalender-2023-vilkar',

  JANUARY_2024_COMPETITION: '/januar-trekning-2024',
  JANUARY_2024_COMPETITION_TERMS: '/januar-trekning-2024-vilkar',

  FEBRUARY_2024_COMPETITION: '/februar-trekning',
  FEBRUARY_2024_COMPETITION_TERMS: '/februar-trekning-vilkar',

  MARCH_2024_COMPETITION: '/paaske-trekning-2024',
  MARCH_2024_COMPETITION_TERMS: '/paaske-trekning-2024-vilkar',

  APRIL_2024_COMPETITION: '/april-trekning',
  APRIL_2024_COMPETITION_TERMS: '/april-trekning-vilkar',

  MAY_2024_COMPETITION: '/mai-trekning',
  MAY_2024_COMPETITION_TERMS: '/mai-trekning-vilkar',

  SUMMER_JUNE_2024_COMPETITION: '/sommer-trekning',
  SUMMER_JUNE_2024_COMPETITION_TERMS: '/sommer-trekning-vilkar',

  SUMMER_JULY_2024_COMPETITION: '/sommer-trekning-forlengelse',
  SUMMER_JULY_2024_COMPETITION_TERMS: '/sommer-trekning-forlengelse-vilkar',

  SUMMER_EXTRA_2024_COMPETITION: '/august-trekning',
  SUMMER_EXTRA_2024_COMPETITION_TERMS: '/august-trekning-vilkar',

  WAKE_UP_2024_COMPETITION: '/host-trekning',
  WAKE_UP_2024_COMPETITION_TERMS: '/host-trekning-vilkar',

  WAKE_UP_PART2_2024_COMPETITION: '/host-trekning-forlengelse',
  WAKE_UP_PART2_2024_COMPETITION_TERMS: '/host-trekning-forlengelse-vilkar',

  ADVENT_2024_COMPETITION: '/julekalender',
  ADVENT_2024_COMPETITION_TERMS: '/julekalender-vilkar',

  JANUARY_2025_COMPETITION: '/januar-trekning',
  JANUARY_2025_COMPETITION_TERMS: '/januar-trekning-vilkar',

  JANUARY_OPEN_2025_COMPETITION: '/gjeldfri-januar',
  JANUARY_OPEN_2025_COMPETITION_TERMS: '/gjeldfri-januar-vilkar',

  FEBRUARY_2025_COMPETITION: '/gjeldfri-februar',
  FEBRUARY_2025_COMPETITION_TERMS: '/gjeldfri-februar-vilkar',

  MARCH_2025_COMPETITION: '/paaske-trekning',
  MARCH_2025_COMPETITION_TERMS: '/paaske-trekning-vilkar',
};

export const economyPagesDisabled = {
  LOAN_MONEY: '/lane-penger',
  UNSECURED_LOAN: '/laan-uten-sikkerhet',
  BEST_CONSUMER_LOAN: '/beste-forbrukslan',
  LOAN_CALCULATOR: '/forbrukslaan-kalkulator',
  CONSUMER_LOAN_WITH_PAYMENT_REMARK: '/forbrukslan-med-betalingsanmerkning',
  CHEAPEST_LOAN: '/billigste-forbrukslaan',
  ABOUT_LOAN: '/laan',
  PRIVATE_LOAN: '/privatlan',
};

export const myScooprPages = {
  MY_SCOOPR: '/min-scoopr',
  MY_SCOOPR_CONSENTS: '/min-scoopr/consents',
  MY_SCOOPR_LOGIN: '/min-scoopr/login',
  MY_SCOOPR_DASHBOARD: '/min-scoopr/dashboard',
  MY_SCOOPR_MY_CONSENTS: '/min-scoopr/personvern',
  MY_SCOOPR_MY_DEBT: '/min-scoopr/min-gjeld',
  MY_SCOOPR_EMAIL: '/min-scoopr/e-post',
  MY_SCOOPR_PHONE: '/min-scoopr/mobilnummer',
  MY_SCOOPR_SAVINGS_POTENTIAL: '/min-scoopr/mitt-sparepotensiale',
  MY_SCOOPR_LOGIN_LOADING: '/min-scoopr/loading',
  MY_SCOOPR_MY_CREDIT_SCORE: '/min-scoopr/min-kredittscore',
};

export const economyPages = {
  MY_DEBT: '/mingjeld',
  CREDIT_CHECK_MYSELF: '/kredittsjekke-seg-selv',
  CREDIT_CHECK_MYSELF_CHAT: '/kredittsjekke-seg-selv/:flow/:step',
  PARTNER_BANKS: '/banker',
  PARTNER_BANK_DETAILS: '/banker/:bankKey',
  DEBT_REGISTER: '/gjeldsregisteret',
  SAVINGS_POTENTIAL: '/sparepotensialet',
  DEBT_INFORMATION: '/norsk-gjeldsinformasjon',
  INTEREST_RATE: '/rente-hva-er-egentlig-det',
  SMALL_LOAN: '/smalan',
  DEBT_FREE: '/gjeldfri',
  CREDIT_CARD_LOAN: '/kredittkortlan',
  DEBT_OVERVIEW: '/gjeldsoversikt',
  REPAYMENT_PERIOD: '/nedbetalingstid',
  COMPARE_LOANS: '/sammenlign-lan',
  REFINANCING_LOANS: '/refinansieringslan',
  COMPARE_CONSUMER_LOANS: '/sammenlign-forbrukslan',
  SAVE_OR_REPAY_LOAN: '/lonner-det-seg-a-spare-eller-nedbetale-lan',
  MICROLOAN: '/slik-fungerer-mikrolan',
  NOMINAL_AND_EFFECTIVE_INTEREST_RATE:
    '/nominell-og-effektiv-rente-hva-er-forskjellen',
  PROPERTY_VALUE: '/boligverdi',
};

const agentPortalRoutes = {
  AGENT_PORTAL: '/agent-portal',
  AGENT_PORTAL_DEBT_REGISTER: '/agent-portal/debt-register',
  AGENT_PORTAL_DEBT_REGISTER_DETAILS: '/agent-portal/debt-register/:web_rec_id',
  AGENT_PORTAL_DEBT_REGISTER_BY_SSN: '/agent-portal/debt-register-ssn',
  AGENT_PORTAL_DEBT_REGISTER_BY_SSN_DETAILS:
    '/agent-portal/debt-register-ssn/:ssn',
  AGENT_PORTAL_DEBT_REGISTER_BY_SSN_DETAILS_NOT_FOUND:
    '/agent-portal/debt-register-ssn/:ssn/not-found',
  AGENT_PORTAL_LOGIN: '/agent-portal/login',
  AGENT_PORTAL_APPLICATIONS: '/agent-portal/applications',
  AGENT_PORTAL_APPLICATION_DETAILS: '/agent-portal/applications/:id',
  AGENT_PORTAL_404: '/agent-portal/404',
  AGENT_PORTAL_EXPIRED_GOOD_CASES: '/agent-portal/expired-good-cases',
  AGENT_PORTAL_EXPIRED_IRRELEVANT_CASES:
    '/agent-portal/expired-irrelevant-cases',
  AGENT_PORTAL_EXPIRED_LIQUIDITY_CASES: '/agent-portal/expired-liquidity-cases',
  AGENT_PORTAL_EXPIRED_TEMP_CASES: '/agent-portal/expired-temp-cases',
  AGENT_PORTAL_FOLLOW_UP_GOOD_CASES: '/agent-portal/follow-up-good-cases/:type',
};

export const routes = {
  ...mainRoutes,
  ...economyPages,
  ...economyPagesDisabled,
  ...myScooprPages,
  ...agentPortalRoutes,
};

export default routes;
