import useBrainSession from '../../../../services/BrainSession/useBrainSession';
import ActionButton from '../../../ActionButton';

interface MenuActionButtonProps {
  className?: string;
  onClick?: any;
  sideBar?: boolean;
  flow?: string;
  step?: string;
}

const MenuActionButton = ({
  className = undefined,
  onClick = undefined,
  sideBar = false,
  flow = 'debt_check',
  step = 'skip_start',
}: MenuActionButtonProps) => {
  const { toggleChat } = useBrainSession();
  return (
    <ActionButton
      label="Søk om lavere rente"
      className={className}
      size="small"
      gaEvent="topCTAClick"
      color="primary"
      flow={flow}
      step={step}
      onClick={
        onClick && sideBar
          ? () => {
              onClick();
              toggleChat({
                goal: flow,
                step: step,
              });
            }
          : onClick
      }
    />
  );
};

export default MenuActionButton;
