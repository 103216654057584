import {
  createContext,
  useCallback,
  useEffect,
  useRef,
  type ReactNode,
} from 'react';
import type { ReadyState, SendMessage } from 'react-use-websocket';
import { useSocketIO } from 'react-use-websocket';

import { API } from './config';
import { useDecodedAccessToken } from './tokens';

export type SocketContextValue = {
  wsSendMessage: SendMessage;
  wsLastMessage: Record<string, any> | null;
  wsReadyState: ReadyState;

  // Computed fields
  wsStatus: string | undefined;
  wsComplete: boolean;
  wsError: boolean;
};

// eslint-disable-next-line react-refresh/only-export-components
export const SocketContext = createContext<SocketContextValue>(undefined!);

export type WsState =
  | {
      wsStatus: 'COMPLETE';
      wsComplete: true;
      wsError: false;
    }
  | {
      wsStatus: 'DATA_FETCHING_ERROR' | 'PARSING_ERROR' | 'ERROR';
      wsComplete: false;
      wsError: true;
    }
  | {
      wsStatus: string | undefined;
      wsComplete: false;
      wsError: false;
    };

// eslint-disable-next-line react-refresh/only-export-components
export const getWsStatus = (
  msg: Record<string, any> | null | undefined,
): WsState => {
  const wsStatus: string | undefined = msg?.payload?.status;

  if (wsStatus === 'COMPLETE') {
    return {
      wsStatus: 'COMPLETE',
      wsComplete: true,
      wsError: false,
    };
  }

  if (
    wsStatus === 'DATA_FETCHING_ERROR' ||
    wsStatus === 'PARSING_ERROR' ||
    wsStatus === 'ERROR'
  ) {
    return {
      wsStatus,
      wsComplete: false,
      wsError: true,
    };
  }

  return { wsStatus, wsComplete: false, wsError: false };
};

export type SocketProviderProps = { children?: ReactNode };

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const lastIdRef = useRef<string>(undefined);
  const {
    sendMessage: wsSendMessage,
    lastMessage: wsLastMessage,
    readyState: wsReadyState,
  } = useSocketIO(API.ws);

  const initWs = useCallback(
    (id: string) => {
      if (id !== lastIdRef.current) {
        wsSendMessage(`42["init",{"foreignId":"${id}"}]`);
        lastIdRef.current = id;
      }
    },
    [wsSendMessage],
  );

  const decodedToken = useDecodedAccessToken();

  // Auto init
  useEffect(() => {
    if (wsReadyState === 1 && decodedToken?.brain_session_id) {
      initWs(decodedToken.brain_session_id);
    }
  }, [wsReadyState, decodedToken?.brain_session_id, initWs]);

  const { wsStatus, wsComplete, wsError } = getWsStatus(wsLastMessage);

  return (
    <SocketContext
      value={{
        wsSendMessage,
        wsLastMessage,
        wsReadyState,
        wsStatus,
        wsComplete,
        wsError,
      }}
    >
      {children}
    </SocketContext>
  );
};
