import type { SxProps } from '@mui/material';
import type { LegacyRef, MutableRefObject, RefCallback } from 'react';
import * as v from 'valibot';

import { getReturnUrl } from './router-utils';

export const contextsFromSchemaError = (error: v.ValiError<any>) => {
  const issues = v.flatten(error.issues);
  const contexts: Record<string, any> = {};

  if (issues.root) {
    contexts['Schema Errors (root)'] = issues.root;
  }

  if (issues.nested) {
    contexts['Schema Errors (nested)'] = issues.nested;
  }

  if (issues.other) {
    contexts['Schema Errors (other)'] = issues.other;
  }

  return contexts;
};

export const mergeRefs =
  <T = any>(
    ...refs: Array<MutableRefObject<T> | LegacyRef<T>>
  ): RefCallback<T> =>
  (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        (ref as MutableRefObject<T | null>).current = value;
      }
    });
  };

export const mergeSx = (...styles: (SxProps<any> | undefined)[]): SxProps =>
  styles.filter(Boolean).flatMap((s) => (Array.isArray(s) ? s : [s]));

export const withSearchParams = (
  url: string,
  params: Record<string, string> | URLSearchParams,
) => {
  const relative = url.startsWith('/');
  const base = location.href;
  const orig = new URL(url, base);

  const newParams = mergeSearchParams(orig.searchParams, params);

  orig.search = newParams.toString();
  const str = orig.toString();

  return relative ? getReturnUrl(str) : str;
};

export const mergeSearchParams = (
  params1: URLSearchParams | Record<string, string>,
  params2: URLSearchParams | Record<string, string>,
) => {
  const mergedParams = new URLSearchParams(params1);
  params2 = new URLSearchParams(params2);
  params2.forEach((value, key) => {
    mergedParams.set(key, value);
  });
  return mergedParams;
};

// This function exists mainly to keep react compiler from complaining
// It is also a bit shorter so that's nice
export const hardNavigate = (url: string) => {
  window.location.href = url;
};
