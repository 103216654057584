const config = {
  release: {
    domain: 'https://scoopr.no',
    api: {
      registerSource: 'https://api.scoopr.no/bi/api/registersource/',
      url: 'https://api.scoopr.no',
      ws: 'wss://wso.scoopr.no',
    },
  },
  dev: {
    domain: 'https://alpha.scoopr.no',
    api: {
      registerSource: 'https://api.alpha.scoopr.no/bi/api/registersource/',
      url: 'https://api.alpha.scoopr.no',
      ws: 'wss://wso.alpha.scoopr.no',
    },
  },
  sandbox: {
    domain: 'https://sandbox.scoopr.no',
    api: {
      registerSource: 'https://api.sandbox.scoopr.no/bi/api/registersource/',
      url: 'https://api.sandbox.scoopr.no',
      ws: 'wss://wso.sandbox.scoopr.no',
    },
  },
};

export const env = (import.meta.env.VITE_STAGE ||
  'release') as keyof typeof config;

if (!config[env]) {
  throw new Error(`Invalid environment: ${env}`);
}

export const API = {
  registerSource: config[env].api.registerSource,
  api: config[env].api.url,
  ws: config[env].api.ws,
  url: config[env].api.url,
  domain: config[env].domain,
};
