import ky from 'ky';

import { API } from './config';
import {
  getCurrentTokensOperation,
  getTokens,
  isTokenExpired,
  obtainNewTokens,
  refreshTokens,
  saveTokens,
  type Tokens,
} from './tokens';

export { HTTPError } from 'ky';

export class FetchError extends Error {
  name = 'FetchError';
}

// Convenient wrapper around fetch, makes it easy to know when network errors occur
export const fetchWithError = async (
  input: string | URL | Request,
  init?: RequestInit,
) => {
  try {
    return await fetch(input, init);
  } catch (e) {
    throw new FetchError('Fetch error', { cause: e });
  }
};

export type ApiClientConfig = {
  canObtainNewTokens: (
    requestUrl: string | undefined,
    requestBody: Record<string, any> | undefined,
  ) => boolean;
  onLogout: () => void;
};

let config: ApiClientConfig | undefined = undefined;

export const initializeApiClient = (apiConfig: ApiClientConfig) => {
  config = apiConfig;
};

export const apiClient = ky.create({
  fetch: fetchWithError,
  prefixUrl: API.url,
  retry: 0,
  credentials: 'include',
  hooks: {
    beforeRequest: [
      () => {
        if (!config) {
          throw new Error(
            'Api client is not initialized, call initializeApiClient() first.',
          );
        }
      },
      async (request) => {
        await getCurrentTokensOperation();
        const token = getTokens();
        if (token) {
          request.headers.set('authorization', `Bearer ${token.accessToken}`);
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401) {
          const tokens = getTokens();
          let newTokens: Tokens | undefined;

          if (canRefreshTokens(tokens)) {
            try {
              newTokens = await refreshTokens();
            } catch {}
          }

          if (
            !newTokens &&
            config!.canObtainNewTokens(
              request.url,
              await request
                .clone()
                .json()
                .catch(() => undefined),
            )
          ) {
            try {
              newTokens = await obtainNewTokens();
            } catch {}
          }

          if (newTokens) {
            saveTokens(newTokens);
            return ky(request, options);
          } else {
            // There could be an update in the background, retry with new tokens
            if (
              getCurrentTokensOperation() ||
              tokens?.refreshToken !== getTokens()?.refreshToken
            ) {
              return ky(request, options);
            }

            console.error('Could not refresh or obtain new token');
            config!.onLogout();
          }
        }
      },
    ],
  },
});

const canRefreshTokens = (
  tokens: Tokens | null | undefined,
): tokens is Tokens => {
  if (tokens?.refreshToken) {
    return !isTokenExpired(tokens.refreshToken);
  }

  return false;
};
