import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'shared/api-client';
import { API } from 'shared/config';
import { useAuthTokens } from 'shared/tokens';
import * as v from 'valibot';

import { hardNavigate } from '../utils';

import { meQuery, MeSchema } from './queries';

import routes from '@/assets/constants/routes-no';
import { addGTMEvent } from '@/lib/analytics/gtm';
import { clearStorage } from '@/lib/clearStorage';
import { useAuth } from '@/services/Auth';

export type UpdateProfileArgs = {
  email?: string;
  phone_number?: string;
};

export const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (args: UpdateProfileArgs) => {
      const res = await apiClient.patch('account/me/', { json: args }).json();
      return v.parse(MeSchema, res);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: meQuery().queryKey });
    },
  });
};

export const useLogout = () => {
  const tokens = useAuthTokens();

  return useMutation({
    mutationFn: async () => {
      // Blacklists the tokens
      await apiClient.post('account/logout/', {
        json: { access: tokens?.accessToken, refresh: tokens?.refreshToken },
      });
    },
    onSettled: async () => {
      clearStorage();
      addGTMEvent('logout');

      // Refreshing page will clear all local state
      // Removes cookies and redirects to the logged out page
      hardNavigate(
        `${API.url}/account/logout/?redirect_url=${window.location.origin}${routes.LOGGED_OUT}`,
      );
    },
  });
};

export const useDeleteAccount = () => {
  const { userId } = useAuth();
  const { mutate: logout } = useLogout();

  return useMutation({
    mutationFn: async () => {
      if (!userId) {
        throw new Error('User ID is required');
      }
      await apiClient.delete('account/delete/');
      addGTMEvent('account_deleted', { user_id: userId });
    },
    onSuccess: async () => {
      logout();
    },
  });
};
