import { useCallback, useContext } from 'react';
import {
  matchRoutes,
  resolvePath,
  UNSAFE_DataRouterContext,
  useLocation,
  type To,
} from 'react-router';

export const usePrefetchRoute = () => {
  const location = useLocation();
  const { router } = useContext(UNSAFE_DataRouterContext)!;

  return useCallback(
    (to: To) => {
      const path = resolvePath(to, location.pathname);
      const matches = matchRoutes(router.routes, path) || [];

      return Promise.all(
        matches?.flatMap(({ route, params }) => [
          route.lazy?.(),
          typeof route.loader === 'function' &&
            route.loader({
              request: new Request(path.pathname),
              params: params,
              context: {},
            }),
        ]),
      );
    },
    [location.pathname, router.routes],
  );
};
