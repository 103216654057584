import { Box, CircularProgress } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { SIZES } from '../../assets/constants/constants';
import { LANG } from '../../services/Config';
import Typography from '../Typography';

const useStyles = makeStyles<{ inline: boolean }>()(
  ({ breakpoints, palette }, { inline }) => ({
    loaderContainer: {
      minHeight: inline ? 0 : `calc(100vh - ${SIZES.topBar.height.small}px)`,
      [breakpoints.up(SIZES.navigationChange)]: {
        minHeight: inline ? 0 : `calc(100vh - ${SIZES.topBar.height.large}px)`,
      },
    },
    flowDirection: {
      flexDirection: 'column',
      margin: 20,
      minHeight: inline ? 0 : `calc(100vh - ${SIZES.topBar.height.small}px)`,
      [breakpoints.up(SIZES.navigationChange)]: {
        minHeight: inline ? 0 : `calc(100vh - ${SIZES.topBar.height.large}px)`,
      },
    },
    loaderText: {
      display: 'block',
      marginTop: 10,
      marginBottom: 10,
      color: palette.neutral.medium,
    },
  }),
);

interface LoaderProps {
  inline?: boolean;
  colored?: boolean;
  text?: string;
  size?: any;
  customColor?: string;
}

const Loader = ({
  inline = false,
  colored = false,
  text,
  size = 30,
  customColor,
}: LoaderProps) => {
  const { classes } = useStyles({ inline });
  const { appStrings } = LANG;

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      flex={1}
      mb={inline ? 0 : 8}
      className={text ? classes.flowDirection : classes.loaderContainer}
    >
      {text ? (
        <Typography variant="p3" color="primary" className={classes.loaderText}>
          {text}
        </Typography>
      ) : null}
      <CircularProgress
        size={size}
        color={colored ? 'secondary' : 'primary'}
        aria-label={appStrings.LOADING}
        style={customColor ? { color: customColor } : undefined}
      />
    </Box>
  );
};

export default Loader;
