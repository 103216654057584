export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

/**
 * Returns given amount of minutes as milliseconds
 */
export const minutes = (n: number) => n * 60 * 1000;

/**
 * Returns given amount of seconds as milliseconds
 */
export const seconds = (n: number) => n * 1000;

/**
 * Formats a number as a price string in Norwegian currency format.
 * @param sum - The number to be formatted as a price.
 * @param precision - The number of decimal places to display.
 * @returns The formatted price string with "kr" suffix.
 */
export const formatPrice = (sum: number, precision: number) => {
  const nonBreakingSpace = '\xa0';
  sum = sum || 0;

  if (sum === 0) {
    return `0${nonBreakingSpace}kr`;
  }

  const formatter = new Intl.NumberFormat('nb-NO', {
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  });

  return `${formatter.format(sum)}${nonBreakingSpace}kr`;
};

export const pluralize = (
  value: number,
  singular: string,
  plural = singular + 's',
) => `${value} ${value === 1 ? singular : plural}`;

export const { format: formatTimestamp } = new Intl.DateTimeFormat('no', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
});

/**
 * Produces a new object with all null and undefined values removed.
 */
export const cleanParams = <T extends Record<string, any>>(obj: T) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc[key as keyof T] = value;
    }
    return acc;
  }, {} as any);

export const downloadBlob = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  link.download = fileName;
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.click();
  URL.revokeObjectURL(url);
};

export const downloadFile = (file: File) => downloadBlob(file, file.name);

export interface OpenFileOpts {
  accept: string;
  onSelect: (files: FileList) => unknown;
}

export const openFile = ({ accept, onSelect }: OpenFileOpts) => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = accept;
  input.onchange = (e) => {
    const target = e.target as HTMLInputElement;
    if (target?.files) {
      onSelect(target.files);
    }
    input.value = '';
  };
  input.click();
};
