import {
  Typography as MuiTypography,
  type TypographyProps as MuiTypographyProps,
} from '@mui/material';

import { customTypography } from '../../assets/styles/typography';

interface TypographyProps {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'subtitle1'
    | 'subtitle2'
    | 'inherit'
    | (string & {});
  component?: string;
  className?: string;
  children?: any;
}

/** @deprecated */
const Typography = ({
  variant = 'inherit',
  children = null,
  component = undefined,
  className = '',
  ...props
}: TypographyProps & MuiTypographyProps) => {
  const isCustom = Object.keys(customTypography).indexOf(variant) > -1;

  return (
    <MuiTypography
      variant={variant}
      // @ts-expect-error
      component={component || (isCustom ? 'p' : undefined)}
      className={className}
      {...props}
    >
      {children}
    </MuiTypography>
  );
};

export default Typography;
