import { CssBaseline } from '@mui/material';
import { LazyMotion } from 'framer-motion';
import {
  Outlet,
  ScrollRestoration,
  createBrowserRouter,
  useRouteError,
} from 'react-router';
import { SocketProvider } from 'shared/socket';

import routes from './assets/constants/routes-no.ts';
import { december2023Routes } from './competitions/2023/december/pages/routes.tsx';
import { advent2024Routes } from './competitions/2024/advent/pages/routes.tsx';
import { april2024Routes } from './competitions/2024/april/pages/routes.tsx';
import { february2024Routes } from './competitions/2024/february/pages/routes.tsx';
import { january2024Routes } from './competitions/2024/january/pages/routes.tsx';
import { march2024Routes } from './competitions/2024/march/pages/routes.tsx';
import { may2024Routes } from './competitions/2024/may/pages/routes.tsx';
import { summerExtra2024Routes } from './competitions/2024/summer-extra/pages/routes.tsx';
import { summer2024Routes } from './competitions/2024/summer/pages/routes.tsx';
import { wakeUp2024Routes } from './competitions/2024/wake-up/pages/routes.tsx';
import { february2025Routes } from './competitions/2025/february/pages/routes.tsx';
import { januaryOpen2025Routes } from './competitions/2025/january-open/pages/routes.tsx';
import { january2025Routes } from './competitions/2025/january/pages/routes.tsx';
import { march2025Routes } from './competitions/2025/march/pages/routes.tsx';
import { Analytics } from './components/Analytics/index.ts';
import Layout from './components/Layout/index.js';
import { NProgress } from './components/NProgress/index.ts';
import { authInitQuery, competitionWinnersQuery } from './lib/api/queries.ts';
import { simpleRace, smartLazy } from './lib/router-utils.tsx';
import LegacyRoutes from './pages/LegacyRoutes.tsx';
import { myScooprRoutes } from './pages/MyScoopr/routes.tsx';
import { queryClient } from './queryClient.ts';
import { AuthProvider } from './services/Auth/index.ts';
import { BrainSessionProvider } from './services/BrainSession/BrainSession.tsx';

const loadFeatures = () =>
  import('./framer-features.ts').then((res) => res.default);

// Can be moved to its own file in the future
// eslint-disable-next-line react-refresh/only-export-components
const RootLayout = () => (
  <>
    <NProgress />
    <CssBaseline />
    <ScrollRestoration />
    <LazyMotion features={loadFeatures} strict>
      <SocketProvider>
        <Outlet />
      </SocketProvider>
    </LazyMotion>
  </>
);

// Can be moved to its own file in the future
// eslint-disable-next-line react-refresh/only-export-components
const UserLayout = () => (
  <AuthProvider>
    <BrainSessionProvider>
      <Analytics />
      <Layout />
    </BrainSessionProvider>
  </AuthProvider>
);

// eslint-disable-next-line react-refresh/only-export-components
const ForwardError = () => {
  // Pass errors to sentry's error boundary higher up in the tree
  throw useRouteError();
};

export const router = createBrowserRouter([
  {
    path: '/',
    Component: RootLayout,
    // Ignore errors in production build (hopefully we'll get proper error screen in the future)
    ErrorBoundary: import.meta.env.PROD ? ForwardError : undefined,
    HydrateFallback: () => (
      <div className="full-page-loader">
        <img width="200" src="/logo.png" alt="Scoopr logo" />
      </div>
    ),
    children: [
      {
        // User portal
        loader: async () => {
          queryClient.prefetchQuery(authInitQuery());
          return null;
        },
        Component: UserLayout,
        children: [
          { path: '*', Component: LegacyRoutes },
          { path: '/', lazy: () => smartLazy(import('./pages/Home/index.ts')) },
          {
            path: routes.PRODUCTS_REFINANCING,
            lazy: () => smartLazy(import('./pages/Refinancing/index.js')),
          },
          {
            path: routes.PRODUCTS_REFINANCING_CHAT,
            lazy: () => smartLazy(import('./pages/Refinancing/index.js')),
          },
          {
            path: routes.ABANDONED_SESSIONS_CHAT,
            lazy: () => smartLazy(import('./pages/Refinancing/index.js')),
          },
          {
            path: routes.DEBT_REGISTER,
            lazy: () => smartLazy(import('./pages/DebtRegister/index.ts')),
          },
          {
            path: routes.CUSTOMER_SERVICE,
            lazy: () => smartLazy(import('./pages/CustomerService/index.ts')),
          },
          {
            path: routes.COMPETITION_WINNERS,
            loader: () =>
              simpleRace([
                queryClient.prefetchQuery(competitionWinnersQuery()),
              ]),
            lazy: () =>
              smartLazy(import('./pages/CompetitionWinners/index.ts')),
          },
          ...myScooprRoutes,
          ...december2023Routes,
          ...january2024Routes,
          ...february2024Routes,
          ...march2024Routes,
          ...april2024Routes,
          ...may2024Routes,
          ...summer2024Routes,
          ...summerExtra2024Routes,
          ...wakeUp2024Routes,
          ...advent2024Routes,
          ...january2025Routes,
          ...januaryOpen2025Routes,
          ...february2025Routes,
          ...march2025Routes,
        ],
      },
    ],
  },
]);
