import { removeTokens } from 'shared/tokens';

import { clearMyScooprStore } from '@/pages/MyScoopr/_store';
import { DOMAIN } from '@/services/Config';

/** Clears all persistent state */
export const clearStorage = () => {
  localStorage.removeItem(DOMAIN.registerSourceSession);
  removeTokens();
  clearMyScooprStore();
};
