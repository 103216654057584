import * as v from 'valibot';

export const LoanCaseSchema = v.object({
  web_rec_id: v.nullish(v.number(), () => undefined),
  loan_case_id: v.number(),
  created_at: v.pipe(v.string(), v.isoTimestamp()),
  closed: v.boolean(),
  status: v.string(),
  paid_out_data: v.nullish(
    v.object({
      loan_app_id: v.number(),
      paid_out_at: v.pipe(v.string(), v.isoTimestamp()),
      amount: v.number(),
      bank_id: v.number(),
      bank_name: v.string(),
    }),
    () => undefined,
  ),
});

export type LoanCase = v.InferOutput<typeof LoanCaseSchema>;

export const LoanCasesDataSchema = v.object({
  has_active_case: v.boolean(),
  last_paid_out_at: v.nullish(
    v.pipe(v.string(), v.isoTimestamp()),
    () => undefined,
  ),
  loan_cases: v.array(LoanCaseSchema),
});

export type LoanCasesData = v.InferOutput<typeof LoanCasesDataSchema>;
