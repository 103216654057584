import { Tab } from '@mui/material';
import { useLocation } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import { PLink } from '@/ui/PLink';

const useStyles = makeStyles()(({ breakpoints, spacing, typography }) => ({
  tabContainer: {
    margin: spacing(0, 1.5),
    [breakpoints.up(1100)]: {
      margin: spacing(0, 3.5),
    },
  },
  tabRoot: {
    minWidth: 0,
    // @ts-expect-error
    ...typography.p3SemiBold,
    padding: spacing(3.75, 0, 2.75),
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

interface TopBarItemProps {
  menuItem: {
    title: string;
    href: string;
  };
  value: string;
}

const TopBarItem = ({ menuItem, ...props }: TopBarItemProps) => {
  const { classes } = useStyles();

  const { search } = useLocation();

  return (
    <div className={classes.tabContainer}>
      <Tab
        label={menuItem.title}
        component={PLink}
        to={`${menuItem.href}${search}`} // Passing params by search from landing page
        disableRipple
        classes={{
          root: classes.tabRoot,
        }}
        tabIndex={0}
        {...props}
      />
    </div>
  );
};

export default TopBarItem;
