import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import HomeIcon from '../../../../assets/icons/MinScooprMenu/HomeIcon';
import MyDebtIcon from '../../../../assets/icons/MinScooprMenu/MyDebtIcon';
import MySavingsIcon from '../../../../assets/icons/MinScooprMenu/MySavingsIcon';
import MyScoreIcon from '../../../../assets/icons/MinScooprMenu/MyScoreIcon';
import { myScooprConsents } from '../../../../pages/MyScoopr/config';
import { useAuth } from '../../../../services/Auth';
import { LANG } from '../../../../services/Config';

import { checkManyConsentsQuery } from '@/lib/api/queries';
import {
  useActiveCase,
  useIsMyScooprDataPending,
} from '@/pages/MyScoopr/_store';
import { PLink } from '@/ui/PLink';

const useStyles = makeStyles()(() => ({
  stickBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1299,
    '& > .MuiBottomNavigationAction-root  > span': {
      fontSize: '0.600rem',
      color: '#9AA3B0',
    },
    '& > .MuiBottomNavigationAction-root > svg': {
      fill: 'none',
      '& > g > path': {
        stroke: '#9AA3B0',
      },
    },
    '& > .Mui-selected > svg': {
      fill: 'none',
      '& > g > path': {
        stroke: '#000 !important',
      },
    },
    '& > .Mui-selected > span': {
      fontSize: '0.600rem',
      color: '#000',
    },

    '& > .MuiBottomNavigationAction-root': {
      borderTop: '2px solid transparent',
      maxWidth: 112,
    },
    '& > .Mui-selected': {
      borderTop: '2px solid #18A0FB',
    },
  },
}));

const MinScooprMenu = () => {
  const { pathname } = useLocation();
  const { classes } = useStyles();
  const [value, setValue] = React.useState(0);
  const { isAnonymous } = useAuth();
  const pending = useIsMyScooprDataPending();
  const { routes } = LANG;
  const { data: consents } = useQuery(checkManyConsentsQuery(myScooprConsents));
  const activeCase = useActiveCase();

  useEffect(() => {
    const s = pathname.split('/');
    if (s && s.length === 3) {
      if (s[2] === 'dashboard') {
        setValue(0);
      } else if (s[2] === 'mitt-sparepotensiale') {
        setValue(1);
      } else if (s[2] === 'min-gjeld') {
        setValue(2);
      } else if (s[2] === 'min-kredittscore') {
        setValue(3);
      } else {
        setValue(5);
      }
    }
  }, [pathname]);

  return consents?.all_check && !isAnonymous && !pending ? (
    <Box sx={{ width: 477 }}>
      <BottomNavigation
        showLabels
        value={value}
        className={classes.stickBottom}
      >
        {/* @ts-expect-error */}
        <BottomNavigationAction
          LinkComponent={PLink}
          label="Oversikt"
          icon={<HomeIcon />}
          to={routes.MY_SCOOPR_DASHBOARD}
        />
        {!activeCase && (
          // @ts-expect-error
          <BottomNavigationAction
            LinkComponent={PLink}
            label="Sparepotensiale"
            icon={<MySavingsIcon />}
            to={routes.MY_SCOOPR_SAVINGS_POTENTIAL}
          />
        )}
        {/* @ts-expect-error */}
        <BottomNavigationAction
          LinkComponent={PLink}
          label="Min Gjeld"
          icon={<MyDebtIcon />}
          to={routes.MY_SCOOPR_MY_DEBT}
        />
        {/* @ts-expect-error */}
        <BottomNavigationAction
          LinkComponent={PLink}
          label="Min kredittscore"
          icon={<MyScoreIcon />}
          to={routes.MY_SCOOPR_MY_CREDIT_SCORE}
        />
      </BottomNavigation>
    </Box>
  ) : null;
};

export default MinScooprMenu;
