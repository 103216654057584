import { ListItem, ListItemText } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { PNavLink } from '@/ui/PLink';

const useStyles = makeStyles()(({ palette, spacing, typography }) => ({
  navItemText: {
    '& > span': {
      fontSize: '1.125rem',
      // @ts-expect-error
      fontWeight: typography.fontWeightSemiBold,
    },
  },
  menuItem: {
    display: 'flex',
    color: palette.text.default,
    justifyContent: 'flex-start',
    padding: spacing(1.5, 8),
    width: '100%',
  },
  menuItemActive: {
    color: palette.primary.main,
  },
}));

interface NavItemProps {
  menuItem: {
    title: string;
    href: string;
  };
  onClick?: any;
}

const NavItem = ({ menuItem, onClick = undefined, ...rest }: NavItemProps) => {
  const { classes } = useStyles();
  const { title, href } = menuItem;

  return (
    <ListItemLink
      to={href}
      {...rest}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <ListItemText className={classes.navItemText}>{title}</ListItemText>
    </ListItemLink>
  );
};

export default NavItem;

const ListItemLink = ({ ...props }) => {
  const { classes, cx } = useStyles();

  return (
    <ListItem
      disableGutters
      className={classes.menuItem}
      component={({ className, ...linkProps }: any) => (
        <PNavLink
          className={({ isActive }) =>
            cx(className, isActive && classes.menuItemActive)
          }
          {...linkProps}
        />
      )}
      {...props}
    />
  );
};
