import { makeStyles } from 'tss-react/mui';

import LogoIcon from '../../assets/icons/LogoIcon';

const useStyles = makeStyles()(({ breakpoints }) => ({
  logoRoot: {
    width: 'auto',
    height: 18,
    [breakpoints.up('md')]: {
      height: 25,
    },
  },
}));

interface LogoProps {
  className?: string;
}

const Logo = ({ className = '', ...rest }: LogoProps) => {
  const { classes, cx } = useStyles();
  return <LogoIcon className={cx(classes.logoRoot, className)} {...rest} />;
};

export default Logo;
