import { Button, Hidden, IconButton, Link, alpha } from '@mui/material';
import { Link as RouterLink } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import { LINKS, SIZES } from '../../../../assets/constants/constants';
import Dots from '../../../../assets/images/dots_doodle.svg?react';
import { LANG } from '../../../../services/Config';
import Logo from '../../../Logo';
import Typography from '../../../Typography';

import FacebookIcon from './_components/icons/FacebookIcon';
import TiktokIcon from './_components/icons/TiktokIcon';

const useStyles = makeStyles()(
  ({ breakpoints, palette, spacing, shadows }) => ({
    footerRoot: {
      width: '100%',
      backgroundColor: palette.background.contrast,
      color: palette.text.contrast,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    footerContent: {
      padding: spacing(0, 4, 8),
      width: '100%',
      maxWidth: SIZES.layout.width.xl,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [breakpoints.up('lg')]: {
        padding: spacing(8, 4),
        flexDirection: 'row',
      },
      [breakpoints.up(1560)]: {
        padding: spacing(8, 0),
        margin: spacing(0, 4),
      },
      [breakpoints.down('md')]: {
        marginTop: 35,
      },
    },
    section: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    contactSection: {
      position: 'relative',
      zIndex: 1050,
      margin: spacing(-8, 'auto', 6),
      backgroundColor: '#FFFFFF',
      color: palette.text.default,
      boxShadow: shadows[21],
      borderRadius: 24,
      width: '100%',
      padding: spacing(3),
      maxWidth: 330,
      [breakpoints.up('lg')]: {
        margin: 0,
        order: 2,
        height: 'min-content',
      },
    },
    mainSection: {
      width: '100%',
      justifyContent: 'space-between',
      [breakpoints.up(360)]: {
        flexDirection: 'row',
      },
      [breakpoints.up('lg')]: {
        paddingRight: spacing(2),
        order: 1,
        textAlign: 'left',
        alignItems: 'flex-start',
      },
    },
    logo: {
      height: 24,
      marginBottom: spacing(1),
      [breakpoints.up('sm')]: {
        marginBottom: spacing(4),
      },
      [breakpoints.up('lg')]: {
        marginBottom: spacing(3),
      },
    },
    link: {
      color: palette.text.contrast,
      marginBottom: spacing(2),
    },
    linkColored: {
      color: palette.text.footerLink,
      marginBottom: spacing(2),
    },
    textAddress: {
      marginBottom: spacing(2),
    },
    buttonFacebook: {
      color: palette.text.contrast,
      margin: spacing(0, 1, 0, -1),
      '&:hover': {
        backgroundColor: alpha(palette.background.default, 0.1),
      },
      '&:focus-visible': {
        backgroundColor: alpha(palette.background.default, 0.1),
      },
      '& svg': {
        width: 30,
        height: 30,
      },
      [breakpoints.down('md')]: {
        '& svg': {
          width: 24,
          height: 24,
        },
      },
    },
    termsLinksContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: spacing(6, 0, 0, 0),
      [breakpoints.up(820)]: {
        display: 'none',
      },
    },
    footerBottom: {
      backgroundColor: '#0C2346',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      height: 70,
      [breakpoints.up('sm')]: {
        height: 90,
      },
    },
    footerBottomContent: {
      margin: spacing(0, 4),
      maxWidth: SIZES.layout.width.xl,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      [breakpoints.up(820)]: {
        justifyContent: 'space-between',
      },
    },
    footerLinks: {
      display: 'none',
      [breakpoints.up(820)]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    footerBottomLink: {
      color: palette.text.contrast,
    },
    dot: {
      backgroundColor: '#354560',
      height: 4,
      width: 4,
      margin: spacing(3),
    },
    dots: {
      position: 'absolute',
      height: 38,
      width: 38,
      left: '50%',
      top: -24,
      transform: 'translate(-50%, -50%) rotate(-40deg)',
      fill: palette.primary.light,
      '& path': { stroke: palette.primary.light },
      [breakpoints.up('lg')]: {
        height: 30,
        width: 30,
        left: 'unset',
        right: -27,
        transform: 'none',
        fill: palette.neutral.dark,
        '& path': { stroke: palette.neutral.dark },
      },
    },
    contactContainer: {
      display: 'flex',
      margin: spacing(1.5, 0, 3),
      justifyContent: 'center',
    },
    halfCircle: {
      width: 54,
      height: 54,
      marginLeft: -27,
      marginRight: spacing(1.5),
      border: `15px solid ${palette.primary.light}`,
      borderRadius: '50%',
      borderBottomColor: 'transparent',
      borderLeftColor: 'transparent',
      transform: 'rotate(-315deg)',
    },
    contactLinks: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    openHoursTable: {
      width: '100%',
      borderSpacing: 0,
      margin: spacing(1, 0, 3),
      '& td': {
        padding: spacing(0.5, 0),
        fontSize: '0.875rem',
      },
      [breakpoints.up('sm')]: {
        '& td': {
          fontSize: '1rem',
        },
      },
    },
    openHours: {
      textAlign: 'right',
      minWidth: 80,
      verticalAlign: 'bottom',
    },
    contactButton: {
      margin: 'auto',
    },
    mainColumn: {
      display: 'flex',
      width: '50%',
      justifyContent: 'center',
      flexDirection: 'column',
      [breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '65%',
      },
    },
    leftColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    leftTopSection: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: spacing(4.5),
    },
    leftEcoSection: {
      display: 'flex',
      flexDirection: 'column',
    },
    rightColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    rightEcoSection: {
      display: 'flex',
      flexDirection: 'column',
    },
    addressColumn: {
      width: '50%',
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.up('md')]: {
        width: '30%',
      },
    },
    ecoAddressColumn: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: spacing(6),
    },
  }),
);

const Footer = () => {
  const { appStrings, routes } = LANG;
  const { classes, cx } = useStyles();

  const mainLinks = [
    { name: 'PRODUCTS_REFINANCING', bold: true },
    { name: 'MY_SCOOPR', bold: true },
    { name: 'CUSTOMER_SERVICE', bold: true },
  ] as const;
  const economyLinks = [
    { name: 'MY_ECONOMY', bold: true },
    { name: 'MY_DEBT', bold: false },
    { name: 'CREDIT_CHECK_MYSELF', bold: false },
    // { name: 'PARTNER_BANKS', bold: false },
    { name: 'DEBT_REGISTER', bold: false },
    { name: 'DEBT_INFORMATION', bold: false },
    { name: 'INTEREST_RATE', bold: false },
    { name: 'SMALL_LOAN', bold: false },
    { name: 'DEBT_FREE', bold: false },
    { name: 'CREDIT_CARD_LOAN', bold: false },
    { name: 'DEBT_OVERVIEW', bold: false },
    { name: 'REPAYMENT_PERIOD', bold: false },
    { name: 'REFINANCING_LOANS', bold: false },
    { name: 'SAVE_OR_REPAY_LOAN', bold: false },
    { name: 'MICROLOAN', bold: false },
    { name: 'NOMINAL_AND_EFFECTIVE_INTEREST_RATE', bold: false },
    { name: 'PROPERTY_VALUE', bold: false },
  ] as const;

  const renderLink = (url: string, title: string, bold: boolean) => {
    return (
      <Link
        component={RouterLink}
        to={url}
        className={bold ? classes.linkColored : classes.link}
        underline="hover"
        key={title}
      >
        <Typography
          component={bold ? 'h4' : 'p'}
          variant={bold ? 'h4n' : 'body3n'}
        >
          {title}
        </Typography>
      </Link>
    );
  };

  return (
    <footer className={classes.footerRoot}>
      <div className={classes.footerContent}>
        <div className={cx(classes.section, classes.contactSection)}>
          <Dots className={classes.dots} />
          <Typography component="h2" variant="h2n">
            {appStrings.CONTACT.TITLE}
          </Typography>

          <div className={classes.contactContainer}>
            <span className={classes.halfCircle} />
            <div className={classes.contactLinks}>
              <Link href={`tel:${appStrings.CONTACT.PHONE2}`} underline="hover">
                <Typography component="h4" variant="h4n">
                  {appStrings.CONTACT.PHONE1}
                </Typography>
              </Link>
              <Link
                href={`mailto:${appStrings.CONTACT.EMAIL}`}
                underline="hover"
              >
                <Typography component="h4" variant="h4n">
                  {appStrings.CONTACT.EMAIL}
                </Typography>
              </Link>
            </div>
          </div>

          <Typography component="h4" variant="h4n">
            {appStrings.CONTACT.OPENING_TITLE}
          </Typography>
          <table className={classes.openHoursTable}>
            <tbody>
              <tr>
                <td>
                  <Typography component="p" variant="body3n">
                    {appStrings.CONTACT.OPENING.MON_THU}:
                  </Typography>
                </td>
                <td className={classes.openHours}>
                  <Typography component="h4" variant="h4n">
                    {appStrings.CONTACT.OPENING.MON_THU_HOURS}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography component="p" variant="body3n">
                    {appStrings.CONTACT.OPENING.FRIDAY}:
                  </Typography>
                </td>
                <td className={classes.openHours}>
                  <Typography component="h4" variant="h4n">
                    {appStrings.CONTACT.OPENING.FRIDAY_HOURS}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography component="p" variant="body3n">
                    {appStrings.CONTACT.OPENING.SATURDAY}:
                  </Typography>
                </td>
                <td className={classes.openHours}>
                  <Typography component="h4" variant="h4n">
                    {appStrings.CONTACT.OPENING.SATURDAY_HOURS}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography component="p" variant="body3n">
                    {appStrings.CONTACT.OPENING.SUNDAY}:
                  </Typography>
                </td>
                <td className={classes.openHours}>
                  <Typography component="h4" variant="h4n">
                    {appStrings.CONTACT.OPENING.SUNDAY_HOURS}
                  </Typography>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <hr className={classes.openHoursLine} />
                </td>
                <td>
                  <hr className={classes.openHoursLine} />
                </td>
              </tr>
              {appStrings.CONTACT.OPENING.EASTER_2024.map(({ day, hours }) => (
                <tr key={day}>
                  <td>
                    <Typography component="p" variant="body3n">
                      {day}:
                    </Typography>
                  </td>
                  <td className={classes.openHours}>
                    <Typography component="h4" variant="h4n">
                      {hours}
                    </Typography>
                  </td>
                </tr>
              ))} */}
            </tbody>
          </table>

          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={routes.CUSTOMER_SERVICE}
            className={classes.contactButton}
          >
            <Typography component="p" variant="button2n">
              {appStrings.MENU.CUSTOMER_SERVICE}
            </Typography>
          </Button>
        </div>

        <div className={cx(classes.section, classes.mainSection)}>
          <div className={classes.mainColumn}>
            <div className={classes.leftColumn}>
              <Logo className={classes.logo} />
              <div className={classes.leftTopSection}>
                {mainLinks.map(({ name, bold }) =>
                  renderLink(routes[name], appStrings.MENU[name], bold),
                )}
              </div>
              <div className={classes.leftEcoSection}>
                {economyLinks
                  .slice(0, 6)
                  .map(({ name, bold }) =>
                    renderLink(routes[name], appStrings.MENU[name], bold),
                  )}
              </div>
            </div>

            <div className={classes.rightColumn}>
              <div className={classes.rightEcoSection}>
                {economyLinks
                  .slice(6, economyLinks.length - 3)
                  .map(({ name, bold }) =>
                    renderLink(routes[name], appStrings.MENU[name], bold),
                  )}
              </div>
              <Hidden mdDown>
                <div className={classes.ecoAddressColumn}>
                  {economyLinks
                    .slice(-3)
                    .map(({ name, bold }) =>
                      renderLink(routes[name], appStrings.MENU[name], bold),
                    )}
                </div>
              </Hidden>
            </div>
          </div>
          <div className={classes.addressColumn}>
            <Hidden mdUp>
              <div className={classes.ecoAddressColumn}>
                {economyLinks
                  .slice(-3)
                  .map(({ name, bold }) =>
                    renderLink(routes[name], appStrings.MENU[name], bold),
                  )}
              </div>
            </Hidden>
            <address>
              <Typography variant="body3n" component="p">
                Scoopr AS
              </Typography>
              <Typography
                variant="body4n"
                component="p"
                className={classes.textAddress}
              >
                Org.nr. 917 864 152
              </Typography>
              <Typography variant="body3n" component="p">
                Scoopr Refinansiering AS
              </Typography>
              <Typography
                variant="body4n"
                component="p"
                className={classes.textAddress}
              >
                Org.nr. 952 660 225
              </Typography>
              <Typography variant="body4n" component="p">
                Karenslyst Allé 2
              </Typography>
              <Typography
                variant="body4n"
                component="p"
                className={classes.textAddress}
              >
                0278 Oslo
              </Typography>
              <IconButton
                component={Link}
                className={classes.buttonFacebook}
                href={LINKS.FACEBOOK}
                rel="noopener noreferrer"
                aria-label={appStrings.CONTACT.FACEBOOK_LABEL}
                size="large"
              >
                <FacebookIcon />
              </IconButton>
              <IconButton
                component={Link}
                className={classes.buttonFacebook}
                href={LINKS.TIKTOK}
                rel="noopener noreferrer"
                aria-label={appStrings.CONTACT.TIKTOK_LABEL}
                size="large"
              >
                <TiktokIcon />
              </IconButton>
            </address>
            <div className={classes.termsLinksContainer}>
              <Link
                component={RouterLink}
                to={routes.TERMS_OF_USE_MIN_SCOOPR}
                className={classes.link}
                underline="hover"
              >
                <Typography component="p" variant="body3n">
                  {appStrings.MENU.TERMS_OF_USE}
                </Typography>
              </Link>
              <Link
                component={RouterLink}
                to={routes.PRIVACY_STATEMENT}
                className={classes.link}
                underline="hover"
              >
                <Typography component="p" variant="body3n">
                  {appStrings.MENU.PRIVACY}
                </Typography>
              </Link>
              <Link
                component={RouterLink}
                to={routes.COOKIES}
                className={classes.link}
                underline="hover"
              >
                <Typography component="p" variant="body3n">
                  {appStrings.MENU.COOKIES}
                </Typography>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.footerBottom}>
        <div className={classes.footerBottomContent}>
          <div className={classes.footerLinks}>
            <Link
              component={RouterLink}
              to={routes.TERMS_OF_USE_MIN_SCOOPR}
              className={classes.footerBottomLink}
              underline="hover"
            >
              <Typography component="p" variant="body3n">
                {appStrings.MENU.TERMS_OF_USE}
              </Typography>
            </Link>
            <span className={classes.dot} />
            <Link
              component={RouterLink}
              to={routes.PRIVACY_STATEMENT}
              className={classes.footerBottomLink}
              underline="hover"
            >
              <Typography component="p" variant="body3n">
                {appStrings.MENU.PRIVACY_STATEMENT}
              </Typography>
            </Link>
            <span className={classes.dot} />
            <Link
              component={RouterLink}
              to={routes.COOKIES}
              className={classes.footerBottomLink}
              underline="hover"
            >
              <Typography component="p" variant="body3n">
                {appStrings.MENU.COOKIES}
              </Typography>
            </Link>
          </div>

          <Typography component="p" variant="body3n">
            {new Date().getFullYear()} {appStrings.RIGHTS}
          </Typography>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
