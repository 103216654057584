import { useQuery, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import React, { createContext, useMemo, type ReactNode } from 'react';
import { apiClient } from 'shared/api-client';
import {
  useAuthTokens,
  useDecodedAccessToken,
  type Tokens,
} from 'shared/tokens';

import { GTM } from '../Config';
import { applyConsents } from '../Http/mutation-fns';

import { getGaClientId } from '@/lib/analytics/gtm';
import { getRegisterSourceData } from '@/lib/analytics/registerSource';
import { useLogout } from '@/lib/api/mutations';
import { authInitQuery } from '@/lib/api/queries';
import { hardNavigate } from '@/lib/utils';

export interface AuthContextValue {
  login: (
    method: 'BANK_ID' | 'VIPPS' | 'SCRIVE',
    returnTo?: string | null,
  ) => Promise<void>;
  logout: () => Promise<void>;
  /** @deprecated use `applyConsents` from `methods.ts` */
  acceptConsents: (consents: string[]) => Promise<void>;
  /** @deprecated */
  getVertex: (clientKnowledge: Record<string, any>) => Record<string, any>;
  token: Tokens | undefined;
  /** @deprecated use `isAuthInit` directly */
  authData: AuthDataState;
  userId: string | undefined;
  isAnonymous: boolean;
  isAuthInit: boolean;
}

type AuthDataState = {
  isAuthInit: boolean;
};

// eslint-disable-next-line react-refresh/only-export-components
export const AuthContext = createContext<AuthContextValue>(undefined!);

export const AuthProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const queryClient = useQueryClient();
  const tokens = useAuthTokens();
  const decodedToken = useDecodedAccessToken();
  const { mutateAsync: logout } = useLogout();

  const { data: initData } = useQuery(authInitQuery());

  const isAuthInit = Boolean(initData);
  const userId = initData?.userId;

  // This is an object for historical reasons, it should be refactored later
  const authData: AuthDataState = useMemo(() => ({ isAuthInit }), [isAuthInit]);

  const isAnonymous = decodedToken?.is_anonymous ?? true;

  /** @deprecated can be extracted after tracking module refactoring is done */
  const getVertex = (
    clientKnowledge: Record<string, string>,
  ): Record<string, any> => {
    const rParams = getRegisterSourceData();
    const getCookie = (name: string) => {
      return Cookies.get(name) || '';
    };

    return {
      client_knowledge: clientKnowledge,
      user_agent: window.navigator.userAgent,
      ga_client_id: getGaClientId(),
      rpcid: rParams.params.rpcid || null,
      ga_session_id: getCookie(GTM.sessionIdCookie),
      cidb: getCookie('_ga'),
      gaexp: getCookie('_gaexp'),
      gcl_au: getCookie('_gcl_au'),
      gcl_aw: getCookie('_gcl_aw'),
      gcl_dc: getCookie('_gcl_dc'),
      fbp: getCookie('_fbp'),
      fbc: getCookie('_fbc'),
      SCOOPR_OPTIMIZE_EXP: getCookie('SCOOPR_OPTIMIZE_EXP'),
      ttclid: getCookie('ttclid'),
      _ttp: getCookie('_ttp'),
      snapchat_uuid_c1: getCookie('uuid_c1'),
      snapchat_sc_cid: rParams.params.ScCid || null,
      page_url: window.location.href,
    };
  };

  /** @deprecated to be removed */
  const acceptConsents = async (consents: string[]) => {
    if (tokens) {
      try {
        await applyConsents(consents);
        await applyConsents.invalidateCache(queryClient);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const login = async (
    method: 'BANK_ID' | 'VIPPS' | 'SCRIVE',
    returnTo?: string | null,
  ) => {
    try {
      const res = await apiClient
        .get('account/login/', {
          searchParams: {
            authentication_provider: method || 'BANK_ID', // For compatibility with js code
            ...(returnTo && { return_to: returnTo }),
          },
        })
        .json<{ url?: string }>();

      if (res?.url) {
        hardNavigate(res.url);
      }
    } catch (e) {
      await logout();
      console.error(e);
    }
  };

  const value: AuthContextValue = {
    acceptConsents,
    login,
    logout,
    getVertex,
    token: tokens,
    authData,
    userId,
    isAnonymous,
    isAuthInit,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
