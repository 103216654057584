import * as Sentry from '@sentry/react';
import { useMemo } from 'react';
import { sumBy } from 'remeda';
import * as v from 'valibot';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import {
  CreditCheckSchema,
  type CSPaymentRemarks,
  type CreditCheckData,
} from './_schemas/credit-check';
import {
  DebtRegisterSchema,
  type DebtRegister,
} from './_schemas/debt-register';
import {
  LoanCasesDataSchema,
  type LoanCase,
  type LoanCasesData,
} from './_schemas/loan-applications';
import {
  PropertiesSchema,
  type PropertiesData,
} from './_schemas/property-value';

import type {
  ChargeCard,
  CreditFacility,
  RepaymentLoan,
} from '@/lib/schemas/debt-register';
import { contextsFromSchemaError } from '@/lib/utils';

type Loadable<T> =
  | { status: 'pending'; data?: undefined }
  | { status: 'error'; data?: undefined }
  | { status: 'success'; data: T };

type Store = {
  debtRegister: Loadable<DebtRegister>;
  properties: Loadable<PropertiesData>;
  creditCheck: Loadable<CreditCheckData>;
  loanCases: Loadable<LoanCasesData>;
};

const getInitialState = (): Store => ({
  debtRegister: { status: 'pending' },
  properties: { status: 'pending' },
  creditCheck: { status: 'pending' },
  loanCases: { status: 'pending' },
});

const useStore = create<Store>()(
  persist(getInitialState, {
    name: 'SCOOPR_USER_DATA',
    storage: createJSONStorage(() => sessionStorage),
    version: 2,
  }),
);

export const clearMyScooprStore = () => {
  useStore.setState(getInitialState(), true);
};

const handleSchemaIssues = (
  issues: [v.BaseIssue<unknown>, ...v.BaseIssue<unknown>[]],
  message: string,
) => {
  const schemaError = new v.ValiError(issues);
  const error = new Error(message, {
    cause: schemaError,
  });
  Sentry.captureException(error, {
    contexts: { ...contextsFromSchemaError(schemaError) },
  });
  console.error(error);
  console.error(v.flatten(issues));
};

export const setPropertiesData = (data: PropertiesData) => {
  const result = v.safeParse(PropertiesSchema, data);
  if (result.success) {
    useStore.setState({
      properties: { status: 'success', data: result.output },
    });
  } else {
    useStore.setState({ properties: { status: 'error' } });
    handleSchemaIssues(result.issues, 'Failed to set properties data');
  }
};

export const usePropertiesData = () => useStore((state) => state.properties);

export const setDebtRegisterData = (data: DebtRegister) => {
  const result = v.safeParse(DebtRegisterSchema, data);
  if (result.success) {
    useStore.setState({
      debtRegister: { status: 'success', data: result.output },
    });
  } else {
    useStore.setState({ debtRegister: { status: 'error' } });
    handleSchemaIssues(result.issues, 'Failed to set debt register data');
  }
};

export type CreditFacilitySummary = {
  type: 'creditFacility';
  name: string;
  loans: CreditFacility[];
  sumCreditLimit: number;
  sumInterestBalance: number;
  sumNonInterestBalance: number;
};

export type RepaymentLoanSummary = {
  type: 'repaymentLoan';
  name: string;
  loans: RepaymentLoan[];
  sumOriginal: number;
  balance: number;
};

export type ChargeCardSummary = {
  type: 'chargeCard';
  name: string;
  loans: ChargeCard[];
  sumInterestBalance: number;
  sumNonInterestBalance: number;
};

export const useDebtRegisterData = () => {
  const debtRegisterData = useStore((state) => state.debtRegister);

  return useMemo(() => {
    if (debtRegisterData.status === 'success') {
      const data = debtRegisterData.data;
      const list = data.debtData?.list;

      const allLoans = {
        repaymentLoan: (() => {
          const loans =
            list
              ?.filter((l) => l.type === 'repaymentLoan')
              .flatMap((l) => l.loans) || [];

          return loans.length > 0
            ? ({
                type: 'repaymentLoan',
                name: 'Nedbetalingslån',
                loans: loans,
                sumOriginal: sumBy(loans, (loan) => loan.originalBalance),
                balance: sumBy(loans, (loan) => loan.balance),
              } satisfies RepaymentLoanSummary)
            : undefined;
        })(),

        creditFacility: (() => {
          const loans =
            list
              ?.filter((l) => l.type === 'creditFacility')
              .flatMap((l) => l.loans) || [];

          return loans.length > 0
            ? ({
                type: 'creditFacility',
                name: 'Rammekreditt',
                loans: loans,
                sumCreditLimit: sumBy(loans, (loan) => loan.creditLimit),
                sumInterestBalance: sumBy(
                  loans,
                  (loan) => loan.interestBearingBalance,
                ),
                sumNonInterestBalance: sumBy(
                  loans,
                  (loan) => loan.nonInterestBearingBalance,
                ),
              } satisfies CreditFacilitySummary)
            : undefined;
        })(),

        chargeCard: (() => {
          const loans =
            list
              ?.filter((l) => l.type === 'chargeCard')
              .flatMap((l) => l.loans) || [];

          return loans.length > 0
            ? ({
                type: 'chargeCard' as const,
                name: 'Faktureringskort',
                loans: loans,
                sumInterestBalance: sumBy(
                  loans,
                  (loan) => loan.interestBearingBalance,
                ),
                sumNonInterestBalance: sumBy(
                  loans,
                  (loan) => loan.nonInterestBearingBalance,
                ),
              } satisfies ChargeCardSummary)
            : undefined;
        })(),
      };

      const unsecuredSum =
        (allLoans.chargeCard?.sumInterestBalance || 0) +
        (allLoans.chargeCard?.sumNonInterestBalance || 0) +
        (allLoans.creditFacility?.sumInterestBalance || 0) +
        (allLoans.creditFacility?.sumNonInterestBalance || 0) +
        (allLoans.repaymentLoan?.balance || 0);

      return {
        status: debtRegisterData.status,
        data: {
          ...data,
          allLoans,
          unsecuredSum,
        },
      };
    } else {
      return debtRegisterData;
    }
  }, [debtRegisterData]);
};

export const setCreditCheckData = (data: CreditCheckData) => {
  const result = v.safeParse(CreditCheckSchema, data);
  if (result.success) {
    useStore.setState({
      creditCheck: { status: 'success', data: result.output },
    });
  } else {
    useStore.setState({ creditCheck: { status: 'error' } });
    handleSchemaIssues(result.issues, 'Failed to set credit check data');
  }
};

export type RiskType = 'veryHigh' | 'high' | 'medium' | 'low' | 'veryLow';

export type RiskRange = {
  label: string;
  scoreRange: [number, number];
  type: RiskType;
};

const RISK: RiskRange[] = [
  { label: 'Svært lav', scoreRange: [0, 20], type: 'veryHigh' },
  { label: 'Ganske lav', scoreRange: [21, 29], type: 'high' },
  { label: 'God', scoreRange: [30, 50], type: 'medium' },
  { label: 'Ganske god', scoreRange: [51, 70], type: 'low' },
  { label: 'Svært god', scoreRange: [71, 100], type: 'veryLow' },
];

export const useCreditCheckData = () => {
  const creditCheckData = useStore((state) => state.creditCheck);

  return useMemo(() => {
    if (creditCheckData.status === 'success') {
      const data = creditCheckData.data;

      const hasRemarks = (remarks: CSPaymentRemarks | undefined) =>
        Boolean(
          remarks?.unsettledROPAmount ||
            remarks?.unsettledROPNumber ||
            remarks?.partlySettledROPAmount ||
            remarks?.partlySettledROPNumber,
        );

      const getRisk = (score: number | undefined) => {
        if (!score || score < 0) return RISK[0];
        score = Math.round(score);

        const risk = RISK.find(
          (item) => score >= item.scoreRange[0] && score <= item.scoreRange[1],
        );

        return risk || RISK[4];
      };

      return {
        status: creditCheckData.status,
        data: {
          ...data,
          remarks: data.scoreData.PaymentRemarks,
          creditScoreRating: data.scoreData.Score?.currentCreditScore.score,
          hasRemarks: hasRemarks(data.scoreData.PaymentRemarks),
          risk: getRisk(data.scoreData.Score?.currentCreditScore.score),
        },
      };
    } else {
      return creditCheckData;
    }
  }, [creditCheckData]);
};

export const setLoanCasesData = (data: LoanCasesData) => {
  const result = v.safeParse(LoanCasesDataSchema, data);
  if (result.success) {
    useStore.setState({
      loanCases: { status: 'success', data: result.output },
    });
  } else {
    useStore.setState({ loanCases: { status: 'error' } });
    handleSchemaIssues(result.issues, 'Failed to set loan cases data');
  }
};

export const useLoanCasesData = () => useStore((state) => state.loanCases);

export type ActiveCase = LoanCase & {
  web_rec_id: number;
  closed: false;
};

export const useActiveCase = () => {
  const activeCase = useStore((state) =>
    state.loanCases.data?.loan_cases.find((c) => !c.closed),
  );

  if (activeCase && !activeCase.web_rec_id) {
    Sentry.captureMessage('Active case has no web_rec_id', { level: 'error' });
    return undefined;
  }

  return activeCase as ActiveCase | undefined;
};

const isPending = (state: Store) =>
  state.creditCheck.status === 'pending' ||
  state.debtRegister.status === 'pending' ||
  state.properties.status === 'pending' ||
  state.loanCases.status === 'pending';

export const isMyScooprDataPending = () => isPending(useStore.getState());

export const useIsMyScooprDataPending = () => useStore(isPending);
