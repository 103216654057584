import { env } from 'shared/config';

import appStringsNo from '../assets/constants/appStrings-no';
import routesNo, { economyPages } from '../assets/constants/routes-no';

export const LANG_CODE = {
  nb: 'nb-NO',
};

export const LANG = {
  appStrings: appStringsNo,
  routes: routesNo,
  economyRoutes: economyPages,
};

const GTM_DATA_STREAM = {
  release: '_ga_GH2XW8SZWJ',
  dev: '_ga_NLJYP1311M',
  sandbox: '_ga_K1EPT8NSG8',
};

export const GTM = {
  // id: env !== 'release' ? 'GTM-WN3TQZP' : 'GTM-WN3TQZP',
  id: env !== 'release' ? 'GTM-TW4S9CJ' : 'GTM-WN3TQZP',
  sessionIdCookie: GTM_DATA_STREAM[env],
};

export const HJ = {
  hjid: 1854012,
  hjsv: 6,
};

export const COOKIEBOOT = {
  cid: 'ad589a4e-20a9-413c-970b-6657d68559de',
};

export const DOMAIN = {
  registerSourceSession: 'SCOOPR_REGISTER_SOURCE_SESSION',
  closeModalUrl: 'SCOOPR_MODAL_URL',
};
