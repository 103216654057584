import { AppBar, IconButton, Tabs, Toolbar } from '@mui/material';
import { useLocation } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import { SIZES, useMenuItems } from '../../../../assets/constants/constants';
import CloseXIcon from '../../../../assets/images/close_x.svg?react';
import HamburgerMenuIcon from '../../../../assets/images/hamburger_menu.svg?react';
import Logo from '../../../../assets/images/logo.svg';
import useIsMobile from '../../../../hooks/IsMobile';
import useIsMyScoopr from '../../../../hooks/IsMyScoopr';
import { LANG } from '../../../../services/Config';
import MenuActionButton from '../MenuActionButton';

import TopBarItem from './_components/TopBarItem/index';

import { PLink } from '@/ui/PLink';

const useStyles = makeStyles()(({ palette, breakpoints, spacing }) => ({
  appBar: {
    alignItems: 'center',
    padding: spacing(0, 2),
    [breakpoints.up('md')]: {
      padding: spacing(0, 4),
    },
  },
  minScoopr: {
    [breakpoints.down('sm')]: {
      background: palette.background.default,
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    height: SIZES.topBar.height.small,
    minHeight: SIZES.topBar.height.small,
    width: '100%',
    maxWidth: SIZES.layout.width.xl,
    padding: 0,
    [breakpoints.up(SIZES.navigationChange)]: {
      justifyContent: 'space-between',
      height: SIZES.topBar.height.large,
      minHeight: SIZES.topBar.height.large,
    },
  },
  openNavButton: {
    position: 'absolute',
    left: spacing(1),
    '& svg': {
      fontSize: '1,875rem',
    },
    [breakpoints.up(SIZES.navigationChange)]: {
      display: 'none',
    },
  },
  logo: {
    marginTop: spacing(0.5),
    height: 18,
    width: 109,
    [breakpoints.up('md')]: {
      height: 25,
    },
  },
  menuContainer: {
    display: 'none',
    [breakpoints.up(SIZES.navigationChange)]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  tabsRoot: {
    height: SIZES.topBar.height.large,
  },
  menuIndicator: {
    borderRadius: 4,
    height: 4,
  },
  button: {
    marginLeft: spacing(3),
  },
}));

const getActiveTab = (path: string, items: any[]) => {
  return items.find((i) => path.startsWith(i.href))?.href || false;
};

interface TopBarProps {
  setSideBarOpen?(...args: unknown[]): unknown;
  isSideBarOpen?: boolean;
}

const TopBar = ({
  setSideBarOpen = () => {},
  isSideBarOpen = false,
  ...rest
}: TopBarProps) => {
  const { classes, cx } = useStyles();
  const isMyScoopr = useIsMyScoopr();
  const { isMobile } = useIsMobile();

  const { appStrings, routes } = LANG;
  const { pathname } = useLocation();

  const menuItems = useMenuItems(appStrings, routes);
  const activeTab = getActiveTab(pathname, menuItems);

  const { search } = useLocation();

  return (
    <AppBar
      className={cx(
        classes.appBar,
        isMyScoopr && isMobile ? classes.minScoopr : null,
      )}
      position="sticky"
      elevation={20}
      {...rest}
    >
      <Toolbar role="toolbar" className={classes.toolbar}>
        <IconButton
          color="primary"
          onClick={setSideBarOpen}
          className={classes.openNavButton}
          aria-label={
            isSideBarOpen ? appStrings.MENU.CLOSE : appStrings.MENU.OPEN
          }
          size="large"
        >
          {isSideBarOpen ? (
            <CloseXIcon color="#18A0FB" />
          ) : (
            <HamburgerMenuIcon />
          )}
        </IconButton>

        <PLink aria-label={appStrings.MENU.HOME} to={`${routes.HOME}${search}`}>
          <img alt="Scoopr logo" src={Logo} className={classes.logo} />
        </PLink>

        <div className={classes.menuContainer}>
          <Tabs
            indicatorColor="primary"
            textColor="primary"
            value={activeTab}
            classes={{ root: classes.tabsRoot }}
          >
            {menuItems.map((item) => (
              <TopBarItem key={item.href} value={item.href} menuItem={item} />
            ))}
          </Tabs>
          <MenuActionButton className={classes.button} />
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
