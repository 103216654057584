import { createContext, useCallback, useEffect, useState } from 'react';

import useLocalStorage from '../../hooks/LocalStorage';
import { useAuth } from '../Auth';
import { DOMAIN } from '../Config';

export type ChatOptions = {
  goal?: string;
  step?: string;
  clientKnowledge?: Record<string, any>;
};

export interface BrainSessionContextType {
  isBobInit: boolean;
  chatOptions: ChatOptions | undefined;
  showChatContainer: boolean;
  closeModalUrl: string | undefined;
  creditScoreRating: number;
  setCreditScoreRating: (rating: number) => void;
  userId: string | undefined;
  toggleChat: (options: ChatOptions) => void;
  closeChatModal: () => void;
  saveCloseModalUrl: (url: string | null) => void;
}

const BrainSessionContext = createContext<BrainSessionContextType>(undefined!);

interface BrainSessionProviderProps {
  children?: React.ReactNode;
}

function BrainSessionProvider({ children = null }: BrainSessionProviderProps) {
  const [showChatContainer, setShowChatContainer] = useState(false);
  const [chatOptions, setChatOptions] = useState<ChatOptions>();
  const [isBobInit, setIsBobInit] = useState(false);
  const [creditScoreRating, setCreditScoreRating] = useState(0);
  const [closeModalUrl, setCloseModalUrl] = useLocalStorage(
    DOMAIN.closeModalUrl,
    undefined,
  );
  const { userId } = useAuth();

  const saveCloseModalUrl = useCallback(
    (url: string | null) => {
      setCloseModalUrl(url);
    },
    [setCloseModalUrl],
  );

  // TODO: rename to `showChat`
  const toggleChat = useCallback((options: ChatOptions) => {
    setChatOptions(options);
    setShowChatContainer(Boolean(options));
  }, []);

  const closeChatModal = () => {
    setShowChatContainer(false);
    setChatOptions(undefined);
  };

  useEffect(() => {
    setIsBobInit(true);
    const params = new URLSearchParams(window.location.search);
    const flowParam = params.get('flow');
    const stepParam = params.get('step');
    if (flowParam && stepParam) {
      toggleChat({ step: stepParam, goal: flowParam });
    }
  }, [toggleChat]);

  const value = {
    isBobInit,
    chatOptions,
    showChatContainer,
    closeModalUrl,
    creditScoreRating,
    setCreditScoreRating,
    userId,
    toggleChat,
    closeChatModal,
    saveCloseModalUrl,
  };

  return (
    <BrainSessionContext.Provider value={value}>
      {children}
    </BrainSessionContext.Provider>
  );
}

export { BrainSessionContext, BrainSessionProvider };
